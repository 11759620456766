@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@400;700&display=swap'); // Also check League Spartan font
@import url('https://use.typekit.net/gqq2xdp.css');
@import './variables.scss';

body {
  background-color: rgb(255, 255, 255);
  font-family: $fontText;
  margin: unset;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fontHeaders;
}

#root {
  display: flex;
  justify-content: flex-end;
  width: 100vw;
  height: 100vh;
  position: relative;
  // overflow-x: hidden;

  a {
    color: black;
    &:visited {
      color: #4b2088;
    }
    &:hover {
      color: #0600e9;
    }
  }

  svg {
    font-size: 2.2rem;
  }
}
