@import '../../style/variables.scss';

.content {
  width: 80%;
  padding: 0 30px;
  height: 400%;
  box-sizing: border-box;

  @include mobile {
    padding: 0 10px;
  }

  .section {
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;

    h2 {
      margin: unset;
      font-size: 2.5rem;
    }
  }
}

.spacer {
  width: 15%;
  height: 100%;
}

.progress {
  background-color: black;
  width: 5%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  // position: fixed;
  // left: 80%;
  // top: 0;

  color: white;
  font-size: 2rem;
  writing-mode: vertical-lr;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // transition: 0.2s ease-in-out;
  position: sticky;
  transition: 100ms;
  box-shadow: 2px 2px 10px grey;

  @include mobile {
    transition: 30ms;
  }

  span {
    line-height: 2rem;
    display: flex;
    margin-bottom: 50px;
    rotate: -180deg;

    @include mobile {
      font-size: 1.4rem;
    }

    i {
      font-size: 0.9rem;
      margin-top: 10px;
      margin-left: -6px;

      @include mobile {
        margin-left: -3px;
        font-size: 0.7rem;
      }
    }
  }
}
