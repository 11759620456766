@import '../../style/variables.scss';

.section.projects {
  flex-direction: row;
  align-items: center;
  width: 100vw;
  margin-left: -30px;
  padding-left: 0;
  padding-right: 0;
  user-select: none;
  box-sizing: border-box;

  @include mobile {
    margin-left: -10px;
  }

  & > svg {
    flex: 1;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    // &:first-of-type {
    //   margin-left: -40px;
    // }

    // &:last-of-type {
    //   margin-right: -55px;
    // }
  }

  #slider {
    flex: 15;
    display: flex;
    gap: 20px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    align-items: center;
    // width: 100%;
    scroll-snap-type: x mandatory;

    .project {
      min-width: 80%;
      display: flex;
      align-items: center;
      background-color: #efefef;
      border-radius: 5px;
      height: fit-content;
      padding: 20px;
      box-sizing: border-box;
      scroll-snap-align: start;

      @include mobile {
        flex-direction: column;
        gap: 10px;
      }

      &.dummy {
        background-color: unset;
        min-width: calc(20% - 20px); // -gap size
      }

      .decription {
        flex: 4;
      }

      .visual {
        flex: 5;
        display: flex;
        justify-content: center;

        img {
          // width: 100%;
          height: 50vh;
          object-fit: cover;

          @include mobile {
            height: 27vh;
            max-width: 65vw;
          }
        }
      }

      .links {
        display: flex;
        gap: 20px;
        align-items: center;
      }
    }

    #histogram {
      display: none;
      flex-direction: column;

      .chartTitle {
        font-family: $fontText;
        font-size: 1rem;
      }

      .axisLabel {
        fill: black;
      }
    }
  }
}
