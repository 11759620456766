@import '../../style/variables.scss';

.education {
  gap: 20px;

  @include mobile {
    li {
      margin-left: -10px;
    }
  }
}
