@import '../../style/variables.scss';

.intro {
  p {
    width: 70%;

    @include mobile {
      width: 100%;
    }

    &:first-of-type {
      margin-bottom: unset;
    }
  }

  .iconsBox {
    display: flex;
    gap: 20px;
    margin-left: -2px;
  }
}
