$fontHeaders: 'Bebas Neue', cursive;
$fontText: 'Poppins', sans-serif;
$fontTest: 'futura-pt', sans-serif;

// Breakpoints
@mixin breakpoint($max-width) {
  @media only screen and (max-width: $max-width) {
    @content;
  }
}

@mixin mobile {
  @include breakpoint(720px) {
    @content;
  }
}
