@import '../../style/variables.scss';

.section.contact {
  justify-content: space-between;
  height: 25%;
  padding: 20px;
  box-sizing: border-box;

  h2,
  p,
  div,
  footer {
    width: 100%;
  }
  p {
    width: 70%;

    @include mobile {
      width: 100%;
    }
  }

  .iconsBox {
    display: flex;
    gap: 20px;
    margin-left: -2px;
  }

  footer {
    p {
      display: flex;
      align-items: center;
      font-size: 0.9rem;

      &:last-of-type {
        font-size: 0.6rem;
        margin: unset;

        @include mobile {
          white-space: nowrap;
        }
      }
    }
    svg {
      margin: 0 5px;
      font-size: 1.2rem !important;
    }
  }
}
